<template>
    <div>

        <v-row justify="center">
            <v-col class="pt-3 pb-0 font-weight-bold text-subtitle-1 mb-0" >
                <span class="mb-0 pb-0"><span class=" black--text">Obra:</span> <span class="">{{values.work}}</span></span>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col class="pt-3 pb-0 font-weight-bold text-subtitle-1 mb-0" >
                <span class="mb-0 pb-0"><span class=" black--text">Proveedor:</span> <span class="">{{values.supplier}}</span></span>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col class="pt-3 pb-0 font-weight-bold text-subtitle-1 mb-0" >
                <span class="mb-0 pb-0" data-cy="date-text"><span class=" black--text">Fecha:</span> <span class="">{{getDate}}</span></span>
            </v-col>
        </v-row>
        <v-row justify="center" class="mb-9">
            <v-col class="pt-3 pb-0 font-weight-bold text-subtitle-1 mb-0" >
                <span class="mb-0 pb-0"><span class=" black--text">Tipo de material:</span> <span class="">{{values.supplies}}</span></span>
            </v-col>
        </v-row>

       
        <v-row class="" v-if="!edit">
            <v-col class="py-2">
               <datePickerForm
                    label="Fecha"
                    :valueInput="values.date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.date = v; delete serverErrors['date']}"
                    :errorMessages="serverErrors['date']"
                    data-cy="date-input"
                ></datePickerForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2" cols="12" >
                <textAreaForm
                    @valueChange="(v) => {values.comments = v}" 
                    :valueInput="values.comments" 
                    :rule="[]" 
                    label="Condiciones"
                    :errorMessages="serverErrors.comments"
                   
                    data-cy="comments-input"
                ></textAreaForm>
            </v-col>
        </v-row>


        <v-row justify="center">
            <v-col class="pt-3 pb-0 font-weight-bold body-2 mb-0" >
                <p class="mb-0 pb-0">Materias primas</p>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Materia prima"
                    :loading="loadingProducts"
                    :valueInput="productSelected"
                    :rule="[]"
                    :items="serverItems.products"
                    :returnObject="true"
                    @valueChange="(v) => {productSelected = v;}"
                    data-cy="productSelected-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="text-right">
                <v-btn
                text
                @click="addProduct()"
                class="font-weight-bold"
                data-cy="addProduct-btn"
                >Agregar</v-btn>
                <!-- <secondary-button data-cy="cancel-btn" :props="addBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button> -->
            </v-col>
        </v-row>
        

        <!-- table -->
        <v-row>
            <v-col class="py-2">
                <table-items data-cy="table-items" :props="tableProps" @methodHandler="method_handler" :items="values.commodities">
                    
                    <template v-slot:item.quantity="props" >

                        <div class="table-item">
                            <textFieldForm 
                                @valueChange="(v) => {props.item.quantity = v; delete serverErrors.quotation_details}" 
                                :valueInput="props.item.quantity" 
                                :rule="rules.cost" 
                                :disabled="props.item.disabled"
                                data-cy="table-quantity-input"
                                
                            ></textFieldForm>
                        </div>
                        
                    </template>

                     <template v-slot:item.amount="props" >

                        <div class="table-item">
                            <textFieldForm 
                                @valueChange="(v) => {props.item.amount = v; delete serverErrors.quotation_details}" 
                                :valueInput="props.item.amount" 
                                :rule="rules.cost" 
                                :disabled="props.item.disabled"
                                data-cy="table-amount-input"
                                
                            ></textFieldForm>
                        </div>
                        
                    </template>

                    <template v-slot:item.subtotal="props" >

                        <div class="table-item">
                          <span data-cy="table-subtotal-input">{{parseFloat(props.item.amount*props.item.quantity).toFixed(2)}}</span>
                        </div>
                        
                    </template>

                   

                    
                </table-items>
                
            </v-col>
            
        </v-row>
        <span class="red--text caption" v-if="serverErrors[`quotation_details`]">{{serverErrors[`quotation_details`]}}</span>
      
      

    </div>
</template>

<script>
import Utils from '../../../helpers/Utils';
/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
            showProductForm:false,
            loadingSaleOrders: false,
            loadingSuppliers: false,
            loadingProducts: false,
            productSelected: "",
            ut: new Utils(),
           
            actions: [
				
				{
					name: 'Borrar',
					icon: {
						color: 'primary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItem',
                    dataCy: "delete-link"
				},
			],
            tableProps: {
				headers: [
                     {
						text: 'Materia prima',
						align: 'left break-words',
						value: 'commodity',
                        
						class: 'table-b-border black--text',
                        sortable: false,
					},
					{
						text: 'Cantidad',
						align: 'center',
						value: 'quantity',
                        width: "15%",
						sortable: false,
						class: 'table-b-border black--text'
					},
                    {
						text: 'Unidad',
						align: 'center',
						value: 'unit',
                        // width: "20%",
						sortable: false,
						class: 'table-b-border black--text'
					},
                    {
						text: 'Costo',
						align: 'center',
						value: 'amount',
                        // width: "20%",
                        width: "15%",
						sortable: false,
						class: 'table-b-border black--text'
					},
                    {
						text: 'Subtotal',
						align: 'center',
						value: 'subtotal',
                        // width: "20%",
						sortable: false,
						class: 'table-b-border black--text'
					},
                   
					{
						text: '',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '10%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: '',
				loading: false
			},
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
               
                cost: [
                    v => {
                        if(v === null || v == undefined || v.toString().trim() === '')
                            return 'El campo es requerido'
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        return true;
                    }
                ],
                quantity: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],
				
				
				
            },

            serverItems:{
              
            
                suppliers: [],
                products: []

            }

            

          
        }
    },
    computed: {
        getDate: function(){
            return Utils.globalDateFormat(this.values.baseDate)
        },
    },
   
    mounted(){
        this.getProducts();
        this.loadProducts();
    },
    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        clear()
        {
            this.values.commodities = [];
            this.serverItems.products = [];
        },
        
     

        getProducts()
        {
            if(this.values.supplier_id == undefined || this.values.supplier_id == "")
                return;

            this.loadingProducts = true;
            this.$api.commodity.bySupplier(this.values.supplier_id)
                .then((resp) => {
                    this.serverItems.products = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingProducts = false;
                })
            ;
        },

        loadProducts()
        {
            this.values.commodities = this.values.commodities.map((item,i) => {
                // x['parameters'] = { id: x.slug, name: x.sku, index: i};
                
              
                const amountItem = parseFloat(item.price??item.amount).toFixed(2);
                let newProd = {
                    actions: JSON.parse(JSON.stringify(this.actions)),
                    parameters: { id: item.commodity_variant_id, name: item.product_name, index: 99},
                    id: item.commodity_variant_id,
                    commodity: `${item.commodity}|${item.size}|${item.color}`,
                    quantity : parseFloat(item.quantity).toFixed(2),
                    unit: item.unit,
                    amount: amountItem,
                    subtotal: parseFloat(item.quantity * amountItem).toFixed(2)
                }
                return newProd;
            })
        },

        addProduct()
        {
            if(!("value" in this.productSelected))
                return;

            let found = this.values.commodities.find(fItem => fItem.id == this.productSelected.value)
            if(found != undefined)
                return;

            let item = this.productSelected;
            const fullname = item.text.split("|");
            const unit = fullname[fullname.length - 1];
            fullname.splice( fullname.length - 1, 1);
            console.log(fullname)
            const name = fullname.join("|");

            let newProd = {
                actions: JSON.parse(JSON.stringify(this.actions)),
                parameters: { id: item.value, name: name, index: 99},
                id: item.value,
                commodity: name,
                unit: unit,
                quantity : 1,   //item.quantity,
                amount: item.price,
                subtotal: parseFloat(item.quantity * item.price).toFixed(2)
            }

            this.values.commodities.push(newProd);
        },

        deleteItem(item)
        {
            let index = this.values.commodities.findIndex(prod => prod.id == item.id);

            console.log(index, item);
            this.values.commodities.splice(index, 1);
        },


    }
}
</script>

<style>

</style>